<template>
  <div
    :class="{appLoggedOff : isLoggedOff}"
    class="appSectionContainer"
    @keydown.esc="closeForms"
  >
    <div
      v-if="canViewSecurity"
      class="mdContainer appSectionBody"
    >
      <div
        v-if="viewRolePermission"
        id="RolesPage"
        ref="RolesPage"
        :class="hasStorm ? 'secTitleStorm' : 'secTitle'"
        @click="setForm('RolesPage')"
      >
        Roles
      </div>
      <div
        v-if="viewPermissionPermission"
        id="PermissionsPage"
        ref="PermissionsPage"
        :class="hasStorm ? 'secTitleStorm' : 'secTitle'"
        @click="setForm('PermissionsPage')"
      >
        Permissions
      </div>
      <div
        v-if="viewAreasPermission"
        id="AreasPage"
        ref="AreasPage"
        :class="hasStorm ? 'secTitleStorm' : 'secTitle'"
        @click="setForm('AreasPage')"
      >
        Sites
      </div>
      <div
        v-if="viewCustomerPermission"
        id="CustomersPage"
        ref="CustomersPage"
        :class="hasStorm ? 'secTitleStorm' : 'secTitle last'"
        @click="setForm('CustomersPage')"
      >
        Top Level Sites
      </div>
      <div
        v-if="hasStorm && updateEntityPermission && showStormTab"
        id="StormPage"
        ref="StormPage"
        class="secTitleStorm stormLast"
        @click="setForm('StormPage')"
      >
        UC Analytics Users
      </div>
      <div class="secFormPanel">
        <component
          :userid="userid"
          :username="username"
          :is="currentForm"
        />
      </div>
    </div>
    <div
      v-else
      class="noResults"
    >
      Sorry, you do not have permission to manage security.
    </div>
  </div>
</template>

<script>
import PermissionsPage from '../components/security/permissionsPage'
import RolesPage from '../components/security/rolesPage'
import AreasPage from '../components/security/areasPage'
import CustomersPage from '../components/security/customersPage'
import StormPage from '../components/security/stormPage'
import { stormMixin } from '../mixins/stormMixin'
import { permissionsMixin } from '../mixins/permissions'
import { DataEventBus } from '../events/dataEvents'
import { mapActions } from 'vuex'
export default {
  name: 'Security',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Maintel iQuote Security' }
    ],
    title: 'iQuote - Security'
  },
  components: {
    PermissionsPage,
    RolesPage,
    AreasPage,
    CustomersPage,
    StormPage
  },
  mixins: [permissionsMixin, stormMixin],
  data () {
    return {
      currentForm: 'RolesPage',
      username: '',
      userid: -1,
      selectedTab: 'rgba(44,62,80,1)',
      unselectedTab: 'rgba(44,62,80,0.7)',
      showStormTab: false
    }
  },
  computed: {
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    canViewSecurity () {
      return this.$store.getters.viewSecurityPermission
    },
    viewAreasPermission () {
      return this.$store.getters.viewAreasPermission
    },
    viewRolePermission () {
      return this.$store.getters.viewRolePermission
    },
    viewPermissionPermission () {
      return this.$store.getters.viewPermissionPermission
    },
    viewCustomerPermission () {
      return this.$store.getters.viewCustomerPermission
    },
    updateEntityPermission () {
      return this.$store.getters.updateEntityPermission
    },
    hasStorm () {
      return this.$store.getters.stormCompany.companyId !== undefined
    }
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.setSecurityForms()
      this.setReloadRoles(true)
      this.setReloadPermissions(true)
      this.setReloadAreas(true)
      this.setStormSecurity()
    })
    this.setSecurityForms()
    this.setStormSecurity()
    this.setPage('Security')
    DataEventBus.$emit('still-active')
  },
  methods: {
    ...mapActions([
      'setPage',
      'setMainForm',
      'setSubForm',
      'setSubForm2',
      'setSubForm3',
      'setReloadRoles',
      'setReloadPermissions',
      'setReloadAreas'
    ]),
    setSecurityForms () {
      if (this.viewRolePermission) {
        this.setForm('RolesPage')
      } else if (this.viewPermissionPermission) {
        this.setForm('PermissionsPage')
      } else if (this.viewAreasPermission) {
        this.setForm('AreasPage')
      } else if (this.viewCustomerPermission) {
        this.setForm('CustomersPage')
      } else if (this.hasStorm) {
        this.setForm('StormPage')
      }
      this.userid = this.$store.getters.userid
      this.username = this.$store.getters.username
    },
    resetTabs () {
      if (this.$refs['RolesPage']) {
        this.$refs['RolesPage'].style.backgroundColor = this.unselectedTab
      }
      if (this.$refs['PermissionsPage']) {
        this.$refs['PermissionsPage'].style.backgroundColor = this.unselectedTab
      }
      if (this.$refs['AreasPage']) {
        this.$refs['AreasPage'].style.backgroundColor = this.unselectedTab
      }
      if (this.$refs['CustomersPage']) {
        this.$refs['CustomersPage'].style.backgroundColor = this.unselectedTab
      }
      if (this.$refs['StormPage']) {
        this.$refs['StormPage'].style.backgroundColor = this.unselectedTab
      }
    },
    setForm (form) {
      this.currentForm = form
      this.resetTabs()
      if (this.$refs[form]) {
        this.$refs[form].style.backgroundColor = this.selectedTab
      }
    },
    closeForms () {
      this.setMainForm(false)
      this.setSubForm(false)
      this.setSubForm2(false)
      this.setSubForm3(false)
    },
    setStormSecurity: async function () {
      // resolve the security groups to check for admin group membership
      this.showStormTab = false
      this.userSecurityGroups = await this.getStormSecurityGroupsByUser(this.$store.getters.stormUser.id)
      this.userSecurityGroups.forEach(sg => {
        if (sg.name.toLowerCase() === this.$store.getters.stormAdminSecurityGroup.toLowerCase() ||
          sg.name.toLowerCase() === this.$store.getters.stormUserSecurityGroup.toLowerCase()) {
          this.showStormTab = true
        }
      })
    }
  }
}
</script>
<style scoped>
  .appSectionBody {
    background: white;
    padding: 15px;
    display:inline-block;
  }
  .last {
    width: calc(25% + 6px);
  }
  .stormLast {
    width: calc(20% + 8px);
  }
</style>
